// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/Xr8CO3Ul8Gb7lVfgMKTh/Embed.js";
import * as localizedValues from "./HH5HV4lwG-0.js";
const EmbedFonts = getFonts(Embed);

const cycleOrder = ["sn_xpfLkD", "f7WppH3Qs", "Rx_hjG763", "WIBmaslox", "YBAkz3I55"];

const serializationHash = "framer-qT8dt"

const variantClassNames = {f7WppH3Qs: "framer-v-1iyv6lt", Rx_hjG763: "framer-v-1nyvplw", sn_xpfLkD: "framer-v-j0uxlc", WIBmaslox: "framer-v-63h7c7", YBAkz3I55: "framer-v-14278pm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"310 BM": "f7WppH3Qs", "310 Veðurstýrðar": "sn_xpfLkD", "AllarStærðir-Snjallborðar": "Rx_hjG763", "Tímastýrð": "YBAkz3I55", ArticleAd: "WIBmaslox"}

const getProps = ({adURL, height, id, width, ...props}) => { return {...props, H7ib_9prj: adURL ?? props.H7ib_9prj ?? "https://app.pulsmedia.is/snjallbordi/ulbdd55b4s2a4h8/", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sn_xpfLkD"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;adURL?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, H7ib_9prj, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sn_xpfLkD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-j0uxlc", className, classNames)} data-framer-name={"310 Veðurstýrðar"} layoutDependency={layoutDependency} layoutId={"sn_xpfLkD"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({f7WppH3Qs: {"data-framer-name": "310 BM"}, Rx_hjG763: {"data-framer-name": "AllarStærðir-Snjallborðar"}, WIBmaslox: {"data-framer-name": "ArticleAd"}, YBAkz3I55: {"data-framer-name": "Tímastýrð"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-2o9b80-container"} layoutDependency={layoutDependency} layoutId={"k818SGp_I-container"}><Embed height={"100%"} html={""} id={"k818SGp_I"} layoutId={"k818SGp_I"} style={{height: "100%", width: "100%"}} type={"url"} url={"https://app.pulsmedia.is/snjallbordi/wph2ww25wyu8ge7/"} width={"100%"} {...addPropertyOverrides({f7WppH3Qs: {url: "https://app.pulsmedia.is/snjallbordi/xw0l70w26ivqg9a/"}, Rx_hjG763: {url: "https://app.pulsmedia.is/snjallbordi/dlmsangcmmvicl0/"}, WIBmaslox: {url: H7ib_9prj}, YBAkz3I55: {url: "https://app.pulsmedia.is/snjallbordi/52zh7h3616wjt33/"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qT8dt.framer-1b80de4, .framer-qT8dt .framer-1b80de4 { display: block; }", ".framer-qT8dt.framer-j0uxlc { height: 400px; overflow: hidden; position: relative; width: 310px; }", ".framer-qT8dt .framer-2o9b80-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-qT8dt.framer-v-1nyvplw.framer-j0uxlc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 600px; justify-content: center; padding: 0px; width: 1018px; }", ".framer-qT8dt.framer-v-1nyvplw .framer-2o9b80-container { bottom: unset; flex: 1 0 0px; height: 100%; left: unset; position: relative; right: unset; top: unset; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qT8dt.framer-v-1nyvplw.framer-j0uxlc { gap: 0px; } .framer-qT8dt.framer-v-1nyvplw.framer-j0uxlc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-qT8dt.framer-v-1nyvplw.framer-j0uxlc > :first-child { margin-left: 0px; } .framer-qT8dt.framer-v-1nyvplw.framer-j0uxlc > :last-child { margin-right: 0px; } }", ".framer-qT8dt.framer-v-14278pm.framer-j0uxlc { height: 250px; width: 300px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 310
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"f7WppH3Qs":{"layout":["fixed","fixed"]},"Rx_hjG763":{"layout":["fixed","fixed"]},"WIBmaslox":{"layout":["fixed","fixed"]},"YBAkz3I55":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"H7ib_9prj":"adURL"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHH5HV4lwG: React.ComponentType<Props> = withCSS(Component, css, "framer-qT8dt") as typeof Component;
export default FramerHH5HV4lwG;

FramerHH5HV4lwG.displayName = "Veðurstýrðar";

FramerHH5HV4lwG.defaultProps = {height: 400, width: 310};

addPropertyControls(FramerHH5HV4lwG, {variant: {options: ["sn_xpfLkD", "f7WppH3Qs", "Rx_hjG763", "WIBmaslox", "YBAkz3I55"], optionTitles: ["310 Veðurstýrðar", "310 BM", "AllarStærðir-Snjallborðar", "ArticleAd", "Tímastýrð"], title: "Variant", type: ControlType.Enum}, H7ib_9prj: {defaultValue: "https://app.pulsmedia.is/snjallbordi/ulbdd55b4s2a4h8/", placeholder: "", title: "AdURL", type: ControlType.String}} as any)

addFonts(FramerHH5HV4lwG, [{explicitInter: true, fonts: []}, ...EmbedFonts], {supportsExplicitInterCodegen: true})